import axios from "axios";

export default {
  data() {
    return {
        web_url: "https://stgseedwebapi.azurewebsites.net/api/v1/",//staging link
        web_urlV2: "https://stgseedwebapi.azurewebsites.net/api/v2/",//staging link
        web_urlV3: "https://stgseedwebapi.azurewebsites.net/api/v3/",//staging link
        web_urlV4:"https://stgseedwebapi.azurewebsites.net/api/v4/", //staging link
        web_urlV6: "https://stgseedwebapi.azurewebsites.net/api/v6/", //staging link
      //  web_url: "https://prodseedconnectwebapi.azurewebsites.net/api/v0/",//production link
      // web_url:"https://localhost:44396/api/v0/",
      env:"STGTest_",
      screentime:18000000,
      menuItems_crud: [],
      menu_items: [],
      file: null,
      uploading: false,
      Loading: true,
      collections: [],
      validation: {
        imagerequired: false,
        wordDescriptionrequired: false,
        timerequired: false,
        daterequired: false,
        enddaterequired: false,
        teacherNamerequired: false,
        courseLocationrequired: false,
        courseNamerequired: false,
        answerreq: false,
      },
      SuccessDialog: false,
    };
  },
  methods: {
    //R11 check permissions for crud..
    check_Permissions(title) {

      let temp_item = JSON.parse(
        localStorage.getItem("menu_items_crud") || "[]"
      );

      let result = temp_item.find((v) => v.title == title).crud_access;

      return result;
    },
    async check_menu_permission() {
      let companyID = localStorage.getItem("companyID");
      let userID = parseInt(localStorage.getItem("UserID"));

      if (companyID == null) {
        return false;
      }
      let request = {
        companyID: companyID,
        userID: userID,
      };
      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/CheckPermissionWeb`,
        request
      );
      this.menu_items = this.itemsData(res.data.data);
      this.menuItems_crud = this.itemsCrudData(res.data.data);

      let outer_permission = [];

      for (const element of this.menu_items) {
        let items__ = [];
        //filter sub_items
        if (element.items) {
          items__ = element.items.filter(
            (v) => v.permission === true
          );
          element.items = items__;
        }
        if (element.title == "setting" || element.title == "home" || element.title == "report") {
          if (element.items.length == 0) {
            outer_permission.push({ title: element.title });
          }
        }
        if (element.permission === false) {
          outer_permission.push({ title: element.title });
        }
      }
      //filter items
      if (outer_permission.length != 0) {
        for (const element of outer_permission) {
          this.menu_items = this.menu_items.filter(
            (t) => t.title != element.title
          );
        }
      }
      localStorage.setItem(
        "menu_items_crud",
        JSON.stringify(this.menuItems_crud)
      );
      localStorage.setItem("menu_items", JSON.stringify(this.menu_items));
    },

    itemsCrudData(data) {
      return [
        //home
        // {
        //   title: "Dashboard",
        //   crud_access: true,
        // },
        {
          title: "library",
          crud_access: data.libraryView,
        },
        {
          title: "lessonlearn",
          crud_access: data.lessonLearnView,
        },
        {
          title: "course",
          crud_access: data.courseView,
          view_type: data.courseAllorGroup,
        },
        {
          title: "quiz",
          crud_access: data.quizView,
          view_type: data.quizAllorGroup,
        },
        {
          title: "community",
          crud_access: data.communityView,
        },
        {
          title: "event",
          crud_access: data.eventView,
        },
        {
          title: "feedbackbox",
          crud_access: data.feedbackView,
        },
        {
          title: "banner",
          crud_access: data.bannerView,
        },
        //..
        {
          title: "usermanagement",
          crud_access: data.userManagementView,
        },
        {
          title: "companyprofile",
          crud_access: data.companyProfileView,
        },
        {
          title: "sendnotification",
          crud_access: data.sendNotificationView,
        },        
        {
          title: "notification",
          crud_access: data.settingNotificationView,
        },
        {
          title: "settingsection",
          crud_access: data.settingNotificationView,
        },
        {
          title: "store",
          crud_access: data.storeView,
        },
        {
          title: "dashboard",
          crud_access: data.dashboardView,
        },
        {
          title: "trainingroadmapiso",
          crud_access: data.skillMatrixView,
        },
      ];
    },

    itemsData(checkdata) {
      return [
        {
          action: "mdi-home-variant-outline",
          active: false,
          items: [
           
            {
              title: "library",
              href: "/home/library",
              permission: checkdata.libraryFlag,
            },
            {
              title: "lessonlearn",
              href: "/home/lesson-learn",
              permission: checkdata.lessonLearnFlag,
            },
            {
              title: "course",
              href: "/home/assignment-course",
              permission: checkdata.courseFlag,
            },
            {
              title: "quiz",
              href: "/home/quiz",
              permission: checkdata.quizFlag,
            },
            {
              title: "community",
              href: "/home/community",
              permission: checkdata.communityFlag,
            },
            {
              title: "event",
              href: "/home/event",
              permission: checkdata.eventFlag,
            },
            {
              title: "feedbackbox",
              href: "/home/feedback-box",
              permission: checkdata.feedbackFlag,
            },
            {
              title: "banner",
              href: "/home/banner",
              permission: checkdata.bannerFlag,
            },
            {
              title: "store",
              href: "/home/store",
              permission: checkdata.storeFlag,
            },
          ],
          title: "home",
        },
        
        {
          action: "mdi-signal-cellular-3",
          active: false,
          href: "/dashboard",
          permission: checkdata.dashboardFlag,
          title: "dashboard",
         
        },
        {
          action: "mdi-file-document",
          active: false,
          items: [
            {
              title: "useractivity",
              href: "/report/user-activity",
              permission: checkdata.userActivityFlag,
            },
            // {
            //   title: "usagemetrics",
            //   href: "/report/usage-metrics",
            //   permission: checkdata.usageMetricsFlag,
            // },
            {
              title: "contentviewrp",//userview report
              href: "/report/content-viewing",//user-view now change to content viewing report
              permission: checkdata.userViewFlag, //user view rp flag
            },
            {
              title: "requesteduserreport",
              href: "/report/requested-user",
              permission: checkdata.requestedUserFlag,
            },
            {
              title: "ACReport",
              href: "/report/assignment-course",
              permission: checkdata.assignmentReportFlag,
            },
          ],
          title: "report",
        },
        {
          action: "mdi-account",
          active: false,
          title: "usermanagement",
          href: "/user-management",
          permission: checkdata.userManagementFlag,
        },
        {
          action: "mdi-briefcase-variant",
          active: false,
          title: "companyprofile",
          href: "/company-profile",
          permission: checkdata.companyProfileFlag,
        },
        {
          action: "mdi-bell-ring",
          active: false,
          title: "sendnotification",
          href: "/notification-page",
          permission: checkdata.sendNotificationFlag,
        },
        {
          action: "mdi-cog-outline",
          active: false,
          items: [
            {
              title: "notification",
              href: "/setting/notificationpage",
              permission: checkdata.settingNotificationFlag,
            },
            {
              title: "email",
              href: "/setting/emailpage",
              permission: checkdata.settingNotificationFlag,
            },
          ],
          title: "setting",
        },
        {
          action: "mdi-road-variant",
          active: false,
          items: [
            {
              title: "skillchart",
              href: "/trainingroadmap/skillchart",
              permission: checkdata.skillMatrixFlag,
              qrcode: checkdata.isoAccess,
            },
            {
              title: "settingsection",
              href: "/trainingroadmap/settingsection",
              permission: checkdata.skillMatrixFlag,
              qrcode: checkdata.isoAccess,
            },
            {
              title: "settingposition",
              href: "/trainingroadmap/settingposition",
              permission: checkdata.skillMatrixFlag,
              qrcode: checkdata.isoAccess,
            },
          ],
          title: "trainingroadmapiso",
          permission: checkdata.skillMatrixFlag,
        },
      ];
    },

    addData(url, body, parse = false) {
      const headers = this.getAuthorizationHeader();

      if (parse) {
        return new Promise((resolve, reject) => {
          axios
            .post(`${this.web_url}${url}`, body, headers)
            .then((res) => resolve(res.data.data))
            .catch((err) => reject(err));
        });
      } else {
        return axios.post(`${this.web_url}${url}`, body, headers);
      }
    },
    // authenticateData(body, authentication) {
    //   if (authentication.active) {
    //     if (authentication.hasOwnProperty("rules")) {
    //       for (const [key, callback] of Object.entries(authentication.rules)) {
    //         if (callback instanceof Function) {
    //           if (key == "*") {
    //             for (const [bodykey, value] of Object.entries(body)) {
    //               if (!callback(value)) return { [bodykey]: false };
    //             }
    //           } else {
    //             if (!callback(body[key])) return { [key]: false };
    //           }
    //         }
    //       }
    //     } else {
    //       for (const [bodykey, value] of Object.entries(body)) {
    //         if (!value) return { [bodykey]: false };
    //       }
    //     }
    //     return { "*": true };
    //   }
    // },
    binarySearchIndex(array, find, key = "id") {
      let start = 0;
      let end = array.length - 1;
      let mid = Math.floor((start + end) / 2);
      while (array[mid][key] !== find && start < end) {
        if (find < array[mid][key]) {
          end = mid - 1;
        } else {
          start = mid + 1;
        }
        mid = Math.floor((start + end) / 2);
      }
      return array[mid][key] !== find ? -1 : mid;
    },
    changeCollections(url, body, ref, props = {}) {
      const options = {
        authentication: {
          active: false,
        },
        // message: "Data changed successfully",
        ...props,
      };

      const { authentication } = options;

      return new Promise((resolve, reject) => {
        const authObj = this.authenticateData(body, authentication);
        if (authObj && !authObj[Object.keys(authObj)[0]]) {
          reject(`auth: ${Object.keys(authObj)[0]}`);
        } else {
          ref.dialog = false;
          this.addData(url, body)
            .then(() => {
              this.clearFields();
              this.loadData();
              resolve();
            })
            .catch((err) => reject(err));
        }
      });
    },
    changeDateFormat(dateString, option = "date") {
      const [formatDate] = dateString.split("T");
      const [year, month, day] = formatDate.split("-");
      const date = new Date(dateString);
      const [hour, minute] = [date.getHours(), date.getMinutes()].map((value) =>
        this.getPrefixZeroString(value, "2")
      );

      let formated_str = "";
      if (option == "date") formated_str = `${day}/${month}/${year}`;
      else if (option == "datetime")
        formated_str = `${day}/${month}/${year} ${hour}:${minute}`;

      return formated_str;
    },
    changeStackCollections(collections) {
      const array = [...collections];
      this.quickSort(array, 0, array.length - 1, "id");
      return array.reverse();
    },
    clearOnCancel(ref) {
      ref.dialog = false;
      for (const key in this.validation) {
        this.validation[key] = false;
      }
      this.clearFields();
      this.$store.dispatch("ClearPermission", false);
      this.$store.dispatch("clearNewPermissionData");
      this.$store.dispatch("createNewUserGpTable", null);
      this.$store.dispatch("chkUserGroup", 2);
      this.$store.dispatch("CreatePartData", null);
      this.$store.dispatch("AccessIsVisible", false);
    },
    formatDuration(duration) {
      let time = [
        Math.floor(duration / 60) + "",
        Math.floor(duration % 60) + "",
      ];
      time.forEach((value) => {
        while (value.length < 2) value = "0" + value;
      });
      return `${time[0]}:${time[1]}`;
    },
    getAuthorizationHeader() {
      return {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("vivek_token"),
        },
      };
    },
    getBase64FileData(file) {
      if (file) {
        const fname = file.name.replace(/\s/g, "");
        const fr = new FileReader();

        fr.readAsDataURL(file);
        return new Promise((resolve, reject) => {
          fr.addEventListener("load", () => {
            if (!fr.result) reject(["", "Fail Reading base64 data"]);
            resolve([fname, fr.result]);
          });
        });
      } else return ["", ""];
    },
    getPrefixZeroString(str, length) {
      let prefixStr = str + "";
      while (prefixStr.length < length) {
        prefixStr = "0" + prefixStr;
      }

      return prefixStr;
    },
    async getCropedUrlString(e) {
        const img = await this.getFileUrlAndName(e);
        const url = img.data;
        return url;
    },
    getData(url, option = {}, props = {}) {
      const body = {
        page: 0,
        per_page: 0,
        ...props,
      };
      const options = {
        parse: false,
        httpVerb: "post",
        ...option,
      };
      const { parse, httpVerb } = options;
      const headers = this.getAuthorizationHeader();

      let promise = null;
      if (httpVerb == "get") {
        promise = axios.get(`${this.web_url}${url}`, headers);
      } else if (httpVerb == "post") {
        promise = axios.post(`${this.web_url}${url}`, body, headers);
      }

      if (promise) {
        if (parse) {
          return new Promise((resolve, reject) => {
            promise
              .then((res) => {
                resolve(res.data.data);
              })
              .catch((err) => reject(err));
          });
        } else {
          return promise;
        }
      }
    },
    async getFileUrlAndName(e) {
      try {
        const headers = this.getAuthorizationHeader();
        let data = {
          base64: e.base64,
          fileName: e.fileName,
        };
        const res = axios.post(
          `${this.web_url}File/UploadFileStringBase`,
          data,
          headers
        );
        return res;
      } catch (error) {
        alert(error);
      }
    },
    async getFileUrlFromServer(file) {
      const res = await this.uploadWithFormData({ file });
      return res.data.data;
    },
    getMediaDuration(url, mediaType = "video") {
      return new Promise((resolve, reject) => {
        if (!url) reject("error getting duration");

        const media = document.createElement(mediaType);
        media.src = url;

        media.addEventListener("loadeddata", () => {
          resolve(media.duration);
        });
      });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
    async checkpermission(v) {
      const headers = this.getAuthorizationHeader();
      let userID = parseInt(localStorage.getItem("UserID"));
      const res = await axios.get(
        `${this.web_url}EmployeeEXT/CheckPermissionByUserID?userid=` +
          userID +
          `&permissionid=` +
          v,
        headers
      );

      return res.data.data;
    },
    partition(array, start, end, key) {
      let pivot = array[end][key];
      let i = start - 1;

      for (let j = start; j <= end - 1; j++) {
        if (array[j][key] <= pivot) {
          i++;
          [array[i], array[j]] = [array[j], array[i]];
        }
      }
      [array[i + 1], array[end]] = [array[end], array[i + 1]];
      return i + 1;
    },
    quickSort(array, start, end, key) {
      if (start < end) {
        let pi = this.partition(array, start, end, key);
        this.quickSort(array, start, pi - 1, key);
        this.quickSort(array, pi + 1, end, key);
      }
    },
    removeCollectionsById(url) {
      return new Promise((resolve, reject) => {
        this.removeDataById(url, this.id)
          .then(async () => {
            if (this.url) this.loadData();
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    removeData(url, body) {
      const headers = this.getAuthorizationHeader();
      return axios.post(`${this.web_url}${url}`, body, headers);
    },
    removeDataById(url, id) {
      const headers = this.getAuthorizationHeader();
      return axios.post(`${this.web_url}${url}`, { id }, headers);
    },
    uploadWithFormData(data = { file: this.file }, props = {}) {
      const options = {
        url: `${this.web_url}File/UploadFile`,
        form_data: null,
        option: {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("vivek_token"),
          },
        },
        ...props,
      };

      const { url, option, form_data } = options;

      if (form_data) return axios.post(url, form_data, option);

      const formData = new FormData();
      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
      }

      return axios.post(url, formData, option);
    },
  },
  mounted() {
    let comID = localStorage.getItem("companyID");
    if (comID) {
      this.$emit("updateMethodCall", "userLogIn");//for nav bar show.
    }
  },

};
